
.form-control{
    border-radius: 0;
    ;
    &:focus{
        border: var(--bs-border-width) solid var(--bs-border-color);
        background-color: $white;
        box-shadow: none
    }
}
.autocomplete-container {
    position: relative; // Stellen Sie sicher, dass das Ergebnispanel relativ zum Container positioniert ist
    #autocomplete-input {
        width: 100%;
    }

    #autocomplete-results {
        
        position: absolute;
        width: 100%;
        max-height: 500px;
        overflow-y: auto;
        z-index: 1000;
        background-color: #fff;
        border: 1px solid $secondary;
        color: #333;
        //border: 1px solid #ddd;

        .autocomplete-item {
            cursor: pointer;
            padding: 8px 12px;
            border-bottom: 1px solid #eee;

            &:hover {
                background-color: #f7f7f7;
                color: #333;
            }

            &.autocomplete-active {
                background-color: #e9e9e9;
            }
            .info{
                float: right;
            }
        }
    }
}
