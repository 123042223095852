// customize some Bootstrap variables
$blue:    #0d6efd;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #d63384;
$red:     #dc3545;
$orange:  #fd7e14;
$yellow:  #ffc107;
$green:   #0a321f;
$teal:    #20c997;
$cyan:    #0dcaf0;
$white: #ffffff;
$light: #defaf4;
$dark: #013220;
$secondary: #FFCC00;
$primary: #174a48;
$theme-colors: (
    "light":      $light,
    "dark":       $dark,
    "primary":    #174a48,
    "secondary":  $secondary,
    "info":       #3dbdaf,
    "success":    #1ba349,
    "warning":    #ecc71c,
    "danger":     #f10d36,
);
$body-bg: #ffffff;

// the ~ allows you to reference things in node_modules
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "./custom.scss";
@import "./autocomplete.scss";

