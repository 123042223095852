.rtl {
    direction: rtl;
    text-align: right;
}

nav {
    background-color: $dark;

    .nav-link {
        color: $light;
    }
}

.accordion-button:not(.collapsed) {
    color: #FFF !important;
    background-color: $dark !important;
}

.form-control{
    background-color: $white;
}

.accordion-button.collapsed,
.accordion-button:link,
.accordion-button:visited,
.accordion-button:hover,
.accordion-button:active {
    background-color: $secondary !important;
    color: $black !important;
}


.accordion-button:focus {
    z-index: 3;
    border-color: #FFF !important;
    outline: 0;
    box-shadow: 0 0 0 .25rem #FFF !important;
}

.header {
    background-color: $dark;
    color: $white;
    
}

.accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");

}

.accordion-button:not(.collapsed) {
    color: $white;
}

/* Change the color of the collapsed arrow */
.accordion-button::after {
    color: red;
    /* Replace 'red' with your desired color */
}

/* Change the color of the expanded arrow */
.accordion-button:not(.collapsed)::after {
    color: blue;
    /* Replace 'blue' with your desired color */
}

.carousel-caption{
    bottom: 0px;
    padding-bottom: 0px;
}
.logo {
   
    img{
        width: 160px;
    }
}
.info{
    font-size: 0.8rem!important;
}
.textarea.cke_source{
    background-color: #000!important;
}

.divider-text {
    position: relative;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
}
.divider-text span {
    padding: 7px;
    font-size: 12px;
    position: relative;
    z-index: 2;
}
.divider-text:after {
    content: "";
    position: absolute;
    width: 100%;
    border-bottom: 1px solid #ddd;
    top: 55%;
    left: 0;
    z-index: 1;
}
@media (max-width: 992px) {
    .navbar-brand {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
}
.searchtext::placeholder{
    text-align: right;
}
.searchtext.rounded.active{
    border-bottom-left-radius: 0!important;
    border-bottom-right-radius: 0!important;
}

@font-face {
    font-family: iransnas;
    src: url(fonts/IRANSansWeb.woff2);
  }

  .category-card {
    position: relative;
    color: white;
    text-align: center;
    background-size: contain;
    height: 600px; /* Höhe der Karte */
}

.category-card .tag {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5); /* Halbtransparenter Hintergrund für die Tags */
    color: white;
    padding: 5px;
    border-radius: 5px;
}
.callout{
    padding: 1.25rem;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    border: 1px solid #e9ecef;
    border-left-width: .25rem;
    border-radius: .25rem;
    .info {
        border-left-color: $teal;
    }
}
.callout
.callout-rtl{
    padding: 1.25rem;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    border: 1px solid #e9ecef;
    border-right-width: .25rem;
    border-radius: .25rem;
    .info {
        border-right-color: $teal;
    }
}

.callout-box{
    text-align: center;
    font-weight: bold;
    padding: 1.25rem;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    border: 1px solid #e9ecef;
    border-left-width: .25rem;
    border-right-width: .25rem;
    border-radius: .25rem;
    &.info{
        border-left-color: $secondary!important;
        border-right-color: $secondary!important;
    }
}